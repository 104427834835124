import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const InnerworksScope = ({ data, images }) => {
	return (
		<Container>
			<Number>
				<SkImage {...images.find((image) => image.name === data.number).image} />
			</Number>
			<Wrapper>
				<TitleWrapper>
					<MainTitle>{data.title}</MainTitle>
					<Icon>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
						>
							<path
								d="M16 0C17.2145 0 18.1386 0.38389 18.7723 1.15167C19.4059 1.91945 19.7228 2.9066 19.7228 4.11311C19.7228 4.99057 19.5644 6.11482 19.2475 7.48586C18.9835 8.80206 18.5875 10.3102 18.0594 12.0103L16.9505 15.9589L20.0396 13.5733C22.0462 11.9829 23.736 10.886 25.1089 10.2828C26.4818 9.62468 27.5908 9.29563 28.4356 9.29563C29.3333 9.29563 30.1518 9.6521 30.8911 10.365C31.6304 11.0231 32 11.9829 32 13.2442C32 14.9443 31.0759 16.0686 29.2277 16.617C27.4323 17.1105 24.8185 17.4396 21.3861 17.6041L17.505 17.7686L20.5941 19.9897C23.0759 21.6898 24.8185 23.1979 25.8218 24.5141C26.8251 25.8303 27.3267 27.1191 27.3267 28.3805C27.3267 29.2579 26.9835 30.0805 26.297 30.8483C25.6106 31.6161 24.7657 32 23.7624 32C22.231 32 21.0165 31.1774 20.1188 29.5321C19.2211 27.8869 18.2706 25.611 17.2673 22.7044L16 19.0026L14.6535 22.7044C13.6502 25.94 12.6469 28.2982 11.6436 29.7789C10.6931 31.2048 9.53135 31.9177 8.15842 31.9177C7.15512 31.9177 6.31023 31.5338 5.62376 30.7661C4.93729 29.9983 4.59406 29.1757 4.59406 28.2982C4.59406 26.982 5.17492 25.6658 6.33663 24.3496C7.49835 22.9786 9.18812 21.5253 11.4059 19.9897L14.4158 17.7686L10.6139 17.6041C6.9703 17.4396 4.27723 17.0831 2.53465 16.5347C0.844885 15.9863 0 14.862 0 13.162C0 11.9006 0.369637 10.9409 1.10891 10.2828C1.90099 9.62468 2.71947 9.29563 3.56436 9.29563C5.62376 9.29563 8.42244 10.7215 11.9604 13.5733L15.0495 15.9589L13.9406 12.0103C13.4125 10.2554 13.0165 8.69237 12.7525 7.32133C12.4884 5.89546 12.3564 4.82605 12.3564 4.11311C12.3564 2.9066 12.6733 1.91945 13.3069 1.15167C13.9406 0.38389 14.8383 0 16 0Z"
								fill="#FC6060"
							/>
						</svg>
					</Icon>
					<Description>{data.text}</Description>
				</TitleWrapper>
				<Items>
					{data.items.map((item, index) => (
						<Item key={item.title} data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}>
							<Image>
								<SkImage {...images.find((image) => image.name === item.image).image} />
							</Image>
							<TextWrapper>
								<Title>{item.title}</Title>
								<Subtitle>{item.subtitle}</Subtitle>
								<Text>{item.text}</Text>
							</TextWrapper>
						</Item>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const Number = styled.div`
	top: 0;
	left: 0;
	z-index: 1;
	position: absolute;
	height: 122px;
	left: -38px;
	top: 20px;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
        height: 165px;
        top: 16px;
        left: 66px;
    `}
	${media.xl`
        height: 265px;
        top: 40px;
        left: 150px;
    `}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 40px 16px 120px 16px;
	${media.md`
        padding: 60px 24px 160px 24px;
        gap: 40px;
    `}
	${media.xl`
        padding: 80px 80px 240px 80px;
        gap: 60px;
    `}
    ${media.xxl`
        padding: 80px 0 240px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	color: ${(p) => p.theme.colors.dark};
	margin-bottom: 12px;
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
        margin-bottom: 24px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
        margin-bottom: 44px;
	`}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	width: 100%;
	text-align: center;
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray2};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
	`}
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	position: inherit;
	z-index: 5;
	${media.md`
        gap: 16px;
    `}
`;

const Icon = styled.div`
	> svg {
		height: 24px;
		width: 24px;
	}
	margin: 0 auto;
	${media.xl`
        > svg {
		    height: 32px;
		    width: 32px;
	    }
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 48px;
	${media.md`
        gap: 40px;
    `}
	${media.xl`
        gap: 60px;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 8px;
        flex-direction: row;
    `}
	&[data-left='true'] {
		${media.md`
            flex-direction: row-reverse;
        `}
	}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t6};
	`}
	line-height: 40px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Subtitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h6};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray2};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height: 22px;
	`}
`;

const Image = styled.div`
	width: 100%;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
        width: 35%;
        display: flex;
        align-items: center;
    `}
	${media.xl`
        width: 50%;
    `}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	width: 100%;
	${media.md`
        gap: 16px;
        min-width: 65%;
        width: 65%;
        justify-content: center;
    `}
	${media.xl`
        min-width: 50%;
        width: 50%
    `}
`;
