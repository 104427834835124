import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../styles/mixins';
import { Breadcrumbs } from '../breadcrumbs';
import { SkImage } from '../shared/image';
import { Link } from '../shared/link';

export const InnerworksHero = ({ data, images, path }) => {
	return (
		<Container>
			<Line>
				<SkImage {...images.find((image) => image.name === 'line').image} />
			</Line>
			<Breadcrumbs isWrapped slug={path || '/case-studies/innerworks'} />
			<Wrapper>
				<TextWrapper>
					<TitleWrapper>
						<Icon>
							<SkImage {...images.find((image) => image.name === data.logo).image} />
						</Icon>
						<Title>{data.title}</Title>
					</TitleWrapper>
					<TagsWrapper>
						{data.tags.map((tag) => (
							<Tag key={tag}>{tag}</Tag>
						))}
					</TagsWrapper>
					<Description>{data.description}</Description>
					<LinkWrapper>
						<Link label={data.link.label} remixIcon={data.link.icon} url={data.link.url} />
					</LinkWrapper>
				</TextWrapper>
				<ImageWrapper>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</ImageWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 24px;
	padding: 16px 16px 0 16px;
	position: inherit;
	z-index: 5;
	${media.md`
        gap: 40px;
        padding: 24px 24px 0 24px;
    `}
	${media.xl`
        display : grid;
        gap: 10px;
        grid-template-columns: 0.60fr 0.4fr;
        padding: 40px 80px 80px 80px;
    `}
    ${media.xxl`
        padding: 40px 0 80px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	gap: 16px;
	${media.xl`
		gap: 24px;
        height: fit-content;
        margin: auto 0;
	`}
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	${media.md`
        gap: 16px;
	`}
	${media.xl`
    	gap: 24px;
		margin-bottom: 16px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
`;

const TagsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	flex-wrap: wrap;
`;

const Tag = styled.div`
	padding: 6px 12px;
	color: ${(p) => p.theme.colors.dark};
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	background: ${(p) => p.theme.colors.bgGray};
	${transition('all')};
	&:hover {
		background: ${(p) => p.theme.colors.purple};
		color: ${(p) => p.theme.colors.white};
	}
	border-radius: 40px;
	${media.md`
		padding: 7px 16px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const Icon = styled.div`
	width: 40px;
	> img {
		width: 100%;
	}
	${media.md`
		width: 48px;
	`}
	${media.xl`
		width: 76px;
	`}
`;

const ImageWrapper = styled.div`
	${media.md`
        height: 480px;
        margin: 0 auto;
        > img {
            width: auto;
            height: 100%;
        }
    `}
	${media.xl`
        height: 615px;
        margin: 0;
    `}
`;

const Description = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.dark};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const LinkWrapper = styled.div`
	> a {
		background: ${(p) => p.theme.colors.gray6};
		font-weight: 500;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height:22px;
		&:hover {
			background: ${(p) => p.theme.colors.purple};
			gap: 8px;
		}
	}
`;

const Line = styled.div`
	position: absolute;
	display: none;
	z-index: 1;
	${media.md`
        display: block;
        top: 60px;
        left: 0;
        width: 100%;
        > img {
            width: 100%;
        }
    `}
	${media.xl`
        top: -70px;
    `}
`;
