import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';

export const InnerworksLast = ({ data, images }) => {
	return (
		<Container>
			<Star>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
				>
					<path
						d="M30 0C30.3494 16.4216 43.5784 29.6506 60 30C43.5784 30.3494 30.3494 43.5784 30 60C29.6506 43.5784 16.4216 30.3494 0 30C16.4216 29.6506 29.6506 16.4216 30 0Z"
						fill="#F5F2EB"
					/>
				</svg>
			</Star>
			<Number>
				<SkImage {...images.find((image) => image.name === data.number).image} />
			</Number>
			<Wrapper>
				<Items>
					{data.items.map((item) => (
						<Item key={item.title}>
							<Icon>
								<RemixIcon className={item.icon} />
							</Icon>
							<Title>{item.title} </Title>
							<Text>{item.text}</Text>
						</Item>
					))}
				</Items>
			</Wrapper>
			<Star>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
				>
					<path
						d="M30 0C30.3494 16.4216 43.5784 29.6506 60 30C43.5784 30.3494 30.3494 43.5784 30 60C29.6506 43.5784 16.4216 30.3494 0 30C16.4216 29.6506 29.6506 16.4216 30 0Z"
						fill="#3F3D56"
					/>
				</svg>
			</Star>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	background: ${(p) => p.theme.colors.dark};
	border-radius: 24px;
	> div:last-child {
		top: auto;
		bottom: -20px;
		z-index: 3;
	}
	${media.md`
        border-radius: 40px;
        > div:last-child {
		    top: auto;
		    bottom: -24px;
	    }
    `}
	${media.xl`
        border-radius: 60px;
        > div:last-child {
		    top: auto;
		    bottom: -30px;
	    }
    `}
`;

const Number = styled.div`
	z-index: 1;
	position: absolute;
	height: 122px;
	right: 0;
	top: 50px;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
        height: 165px;
        top: 45px;
    `}
	${media.xl`
        height: 265px;
        top: 60px;
    `}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 80px 16px;
	position: inherit;
	z-index: 5;
	${media.md`
        padding: 120px 24px;
    `}
	${media.xl`
        padding: 160px 80px;
    `}
    ${media.xxl`
        padding: 160px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        display: grid;
        gap: 8px;
        grid-template-columns: repeat(2, 1fr);
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.md`
        padding: 24px;
        box-sizing: border-box;
    `}
	${media.xl`
        padding: 40px;
    `}
`;

const Star = styled.div`
	max-height: 40px;
	position: absolute;
	left: 50%;
	transform: translate(-50%);
	z-index: 6;
	top: -20px;
	> svg {
		width: 40px;
		height: 40px;
	}
	${media.md`
        max-height: 48px;
        top: -24px;
        > svg {
		    width: 48px;
            height: 48px;
	    }
    `}
	${media.xl`
        max-height: 60px;
        top: -30px;
        > svg {
		    width: 60px;
            height: 60px;
	    }
    `}
`;

const Icon = styled.div`
	color: ${(p) => p.theme.colors.white};
	width: 56px;
	height: 56px;
	min-width: 56px;
	min-height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	background: ${(p) => p.theme.colors.lightDark};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	${media.xl`
        border-radius: 16px
        width: 64px;
	    height: 64px;
        min-width: 64px;
	    min-height: 64px;
        font-size: 27px;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
	    line-height: 40px;  
    `}
	color: ${(p) => p.theme.colors.white};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
        height: auto;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.white};
	letter-spacing: 0.01em;
	width: 240px;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height: 22px;
        width: 50%;
	`};
`;
