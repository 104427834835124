import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const InnerworksFunctionality = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<Number>
					<SkImage {...images.find((image) => image.name === data.number).image} />
				</Number>
				<TextWrapper>
					<Title>{data.title}</Title>
					<Text>{data.text}</Text>
				</TextWrapper>
				<Images>
					{data.images.map((item) => (
						<Image key={item}>
							<SkImage {...images.find((image) => image.name === item).image} />
						</Image>
					))}
				</Images>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 40px 16px;
	${media.md`
        padding: 60px 24px;
        gap: 40px;
    `}
	${media.xl`
        padding: 80px;
        gap: 60px;
    `}
    ${media.xxl`
        padding: 80px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 100%;
	position: inherit;
	z-index: 5;
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	width: 100%;
	text-align: center;
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray2};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
	`}
`;

const Images = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    `}
	${media.xl`
        grid-template-columns: repeat(4, 1fr);
    `}
    > div {
		&:nth-child(2) {
			border-radius: 16px;
		}
		&:nth-child(3) {
			border-radius: 16px;
		}
		&:nth-child(4) {
			border-radius: 16px 16px 200px 200px;
			padding-bottom: 30px;
		}
		${media.md`
            &:nth-child(2) {
			    border-radius: 200px 200px 16px 16px;
		    }
            &:nth-child(3) {
			    border-radius: 16px 16px 200px 200px;
			    padding-bottom: 30px;
		    }
        `}
		${media.xl`
            border-radius: 200px 200px 16px 16px !important;
            padding: 0;
        `}
	}
`;

const Number = styled.div`
	top: 0;
	left: 0;
	z-index: 1;
	position: absolute;
	height: 122px;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
        height: 165px;
    `}
	${media.xl`
        height: 265px;
    `}
`;

const Image = styled.div`
	border: 2px solid ${(p) => p.theme.colors.border};
	border-radius: 200px 200px 16px 16px;
`;
