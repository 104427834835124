import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const InnerworksPlans = ({ data, images }) => {
	return (
		<Container>
			<Number>
				<SkImage {...images.find((image) => image.name === data.number).image} />
			</Number>
			<Wrapper>
				<MainTitle>{data.title}</MainTitle>
				<Items>
					{data.items.map((item, index) => (
						<>
							<Item key={item.title}>
								<Image>
									<SkImage {...images.find((image) => image.name === item.image).image} />
								</Image>
								<Title>{item.title}</Title>
								<Text>{item.text}</Text>
							</Item>
							{index === 0 && (
								<LineWrapper>
									<Line />
								</LineWrapper>
							)}
						</>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const Number = styled.div`
	top: 0;
	left: 0;
	z-index: 1;
	position: absolute;
	height: 122px;
	left: 0;
	top: 50px;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
        height: 165px;
        top: 18px;
    `}
	${media.xl`
        height: 265px;
        top: 154px;
    `}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 40px 16px 80px 16px;
	position: inherit;
	z-index: 5;
	${media.md`
        padding: 60px 24px 120px 24px;
    `}
	${media.xl`
        padding: 80px 80px 160px 80px;
        gap: 60px;
    `}
    ${media.xxl`
        padding: 80px 0 160px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 80px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
    `}
`;

const Item = styled.div`
	display: flex;
	gap: 12px;
	flex-direction: column;
	align-items: center;
	${media.md`
        gap: 16px;
    `}
`;

const Image = styled.div`
	width: 180px;
	height: auto;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
        width: 240px;
    `}
	${media.xl`
        width: 280px;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	text-align: center;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
	    line-height: 40px;  
    `}
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
        height: auto;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray2};
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height: 22px;
	`}
`;

const LineWrapper = styled.div`
	margin: -64px auto;
	${media.md`
        margin: 0;
        position: absolute;
        left: 50%;
	    transform: translate(-50%);
        margin-top: 120px;
    `}
	${media.xl`
        margin-top: 140px;
    `}
`;

const Line = styled.div`
	height: 48px;
	width: 0px;
	border: 2px dashed ${(p) => p.theme.colors.lightGray1};
	position: relative;
	&:after,
	&:before {
		content: ' ';
		position: absolute;
		height: 10px;
		width: 10px;
		background: ${(p) => p.theme.colors.lightGray1};
		border-radius: 50px;
	}
	&::before {
		top: -10px;
		left: -5px;
	}
	&::after {
		content: ' ';
		bottom: -10px;
		left: -5px;
	}
	${media.md`
        height: 0;
        width: calc(100vw*0.097);
        &::before{
            left: -10px;
            top: -5px;
        }
        &::after{
            right: -10px;
            left: auto;
            bottom: -5px;
        }
    `}
	${media.xl`
        width:calc(100vw*0.168);
    `}
`;
