import React from 'react';
import styled, { css } from 'styled-components';

import { highlightString } from '@utils';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const InnerworksAchievements = ({ data, images }) => {
	return (
		<Container>
			<Number>
				<SkImage {...images.find((image) => image.name === data.number).image} />
			</Number>
			<Wrapper>
				<Title>{data.title}</Title>
				<Items>
					{data.items.map((item) => (
						<Item key={item.title}>
							<TitleWrapper>
								<Image>
									<SkImage {...images.find((image) => image.name === item.image).image} />
								</Image>
								<ColorTitle
									dangerouslySetInnerHTML={{
										__html: highlightString(item.title),
									}}
								/>
							</TitleWrapper>
							<Text>{item.text}</Text>
						</Item>
					))}
				</Items>
				<Fan>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</Fan>
			</Wrapper>
			<SkImage {...images.find((image) => image.name === 'wind').image} />
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	> img {
		position: absolute;
		width: auto;
		height: 400px;
		bottom: 50px;
	}
	${media.md`
        > img {
		    position: absolute;
		    width: 100%;
		    height: auto;
		    bottom: 150px;
	    }
    `}
	${media.xl`
        > img {
		    position: absolute;
		    width: 100%;
		    height: auto;
		    bottom: 50px;
	    }
    `}
	@media (min-width: 1900px) {
		> img {
			bottom: 0;
		}
	}
`;

const Wrapper = styled.div`
	position: inherit;
	z-index: 10;
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 40px 16px 40px 16px;
	${media.md`
        padding: 60px 24px 60px 24px;
        gap: 40px;
    `}
	${media.xl`
        padding: 80px 80px 80px 80px;
        gap: 60px;
    `}
    ${media.xxl`
        padding: 160px 0 80px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    `}
	${media.xl`
        width: 50%;
        margin: 0 auto;
    `}
    > div {
		&:nth-child(1) {
			span {
				color: ${(p) => p.theme.colors.red};
			}
		}
		&:nth-child(2) {
			span {
				color: ${(p) => p.theme.colors.purple2};
			}
		}
		&:nth-child(3) {
			span {
				color: ${(p) => p.theme.colors.green};
			}
		}
		&:nth-child(4) {
			span {
				color: ${(p) => p.theme.colors.gray2};
			}
		}
	}
`;

const Fan = styled.div`
	width: 100%;
	height: calc(100vw - 32px);
	position: relative;
	margin-top: 40px;
	z-index: 5;
	> img {
		width: 100%;
		height: auto;
		position: absolute;
		animation: rotate 3s linear infinite;
	}
	${media.md`
        height: 520px;
        width: 520px;
        margin: 40px auto 0 auto;
    `}
	${media.xl`
        height: 800px;
        width: 800px;
        margin: 60px auto 0 auto;
    `}
    @keyframes rotate {
		0% {
			transform: rotate(0deg) scaleX(-1);
		}
		100% {
			transform: rotate(360deg) scaleX(-1);
		}
	}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 24px;
	box-sizing: border-box;
	background: ${(p) => p.theme.colors.white};
	border-radius: 16px;
	box-shadow: ${(p) => p.theme.shadows.gray};
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`;

const Image = styled.div`
	> img {
		width: 100%;
		height: auto;
	}
	width: 35px;
	height: 35px;
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.dark};
`;

const ColorTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h3};
	`}
	line-height: 48px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
`;

const Number = styled.div`
	top: 16px;
	left: -42px;
	z-index: 1;
	position: absolute;
	height: 122px;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
        left: auto;
        top: 0;
        right: -9px; 
        height: 165px;
    `}
	${media.xl`
        top: 160px;
        right: 10%; 
        height: 265px;
    `}
`;
