import React from 'react';
import styled, { css } from 'styled-components';

import { highlightString } from '@utils';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const InnerworksScreens = ({ data, images }) => {
	return (
		<Container>
			<Number>
				<SkImage {...images.find((image) => image.name === data.number).image} />
			</Number>
			<Wrapper>
				<MainTitle>{data.title}</MainTitle>
				<Items>
					{data.items.map((item, index) => (
						<Item
							key={item.title}
							data-type={item.type}
							data-left={(index + 1) % 2 === 0 ? 'true' : 'false'}
						>
							<Image data-type={item.type}>
								<SkImage {...images.find((image) => image.name === item.image).image} />
							</Image>
							{item.type === 'default' && (
								<TextWrapper data-first={index === 0 && 'true'}>
									<Title>{item.title} </Title>
									<Text
										dangerouslySetInnerHTML={{
											__html: highlightString(item.text),
										}}
									/>
								</TextWrapper>
							)}
						</Item>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const Number = styled.div`
	top: 0;
	left: 0;
	z-index: 1;
	position: absolute;
	height: 122px;
	left: 0;
	top: 30px;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
        height: 165px;
        top: 36px;
    `}
	${media.xl`
        height: 265px;
        top: 52px;
    `}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 40px 0;
	position: inherit;
	z-index: 5;
	${media.md`
        padding: 60px 24px;
    `}
	${media.xl`
        padding: 80px;
        gap: 60px;
    `}
    ${media.xxl`
        padding: 80px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	> div {
		&:nth-child(1) {
			background: ${(p) => p.theme.colors.dark};
			${media.xl`
                background: none;
            `}
		}
		&:nth-child(2) {
			background: ${(p) => p.theme.colors.green};
			${media.xl`
                background: none;
            `}
		}
	}
`;

const Item = styled.div`
	&[data-type='default'] {
		border-radius: 24px;
		${media.md`
            border-radius: 40px;
        `}
		${media.xl`
            border-radius: 60px;
            background: none;
        `}
		display: flex;
		flex-direction: column;
		${media.xl`
            flex-direction: row-reverse;
        `}
		&[data-left='true'] {
			${media.xl`
                flex-direction: row;
            `}
		}
	}
`;

const TextWrapper = styled.div`
	padding: 24px;
	box-sizing: border-box;
	gap: 8px;
	display: flex;
	flex-direction: column;
	border-radius: 24px;
	${media.md`
        padding: 40px;
        border-radius: 40px;
    `}
	${media.xl`
        min-width: 45%;
        width: 45%;
        padding: 60px;
        justify-content: center;
        border-radius: 60px;
    `}
    background: ${(p) => p.theme.colors.green};
	&[data-first='true'] {
		background: ${(p) => p.theme.colors.dark};
	}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.white};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
		line-height: 72px;
    `}
`;

const Text = styled.p`
	font-weight: 400;
	color: ${(p) => p.theme.colors.white};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.md`
	    ${(p) => css`
				font-size: ${p.theme.typography.sizes.h6};
			`}
	    line-height: 22px;
    `}
	letter-spacing: 0.01em;
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
	> span {
		font-weight: 500;
		margin-bottom: 8px;
	}
`;

const Image = styled.div`
	> img {
		height: 100%;
		width: auto;
		max-width: fit-content;
	}
	height: 556px;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 24px;
	overflow: hidden;
	${media.md`
        height: 740px;
        border-radius: 40px;
    `};
	${media.xl`
        width: 55%;
        height: fit-content;
        border-radius: 60px;
        > img {
            width: 100%;
            height: auto;
        }
    `};
	&[data-type='long'] {
		width: 100%;
		> img {
			width: auto;
			height: 100%;
		}
		height: 360px;
		${media.md`
            height: 520px;
        `}
		${media.xl`
            height: 540px;
        `}
	}
`;
