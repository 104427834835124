import React from 'react';
import { navigate } from 'gatsby';

import { PopupProvider } from '@context/popup-context';
import { TemplateProps } from '@templates/types';

import { GetInTouch } from '../../components/get-in-touch';
import { InnerworksAbout } from '../../components/innerworks/about';
import { InnerworksAchievements } from '../../components/innerworks/achievements';
import { InnerworksDark } from '../../components/innerworks/dark';
import { InnerworksFunctionality } from '../../components/innerworks/functionality';
import { InnerworksHero } from '../../components/innerworks/hero';
import { InnerworksLast } from '../../components/innerworks/last';
import { InnerworksOptimization } from '../../components/innerworks/optimization';
import { InnerworksPlans } from '../../components/innerworks/plans';
import { InnerworksScope } from '../../components/innerworks/scope';
import { InnerworksScreens } from '../../components/innerworks/screens';
import { Popup } from '../../components/popup';
import { ReviewSection } from '../../components/review';
import { Seo } from '../../components/shared/seo';
import MainLayout from '../../layouts/main-layout';

export const InnerworksTemplate = (props: TemplateProps) => {
	const { page, menu, header, footer, cookieBanner, theme, ctaPopup, seo } = props.pageContext;
	const attributes = page.attributes;

	if (!page) {
		return navigate('/404');
	}
	const { GlobalMeta } = seo.data.attributes;

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
			popup={ctaPopup}
		>
			<Seo meta={page.attributes.meta} globalMeta={GlobalMeta} url={page.attributes.url} />
			{attributes && (
				<PopupProvider>
					{ctaPopup?.data && (
						<Popup
							form={ctaPopup.data.attributes.form}
							formResponse={ctaPopup.data.attributes.formResponse}
						/>
					)}
					<InnerworksHero
						data={attributes.content.hero}
						images={attributes.images}
						path={attributes.url}
					/>
					<InnerworksAbout data={attributes.content.section_1} images={attributes.images} />
					<InnerworksFunctionality data={attributes.content.section_2} images={attributes.images} />
					<InnerworksScope data={attributes.content.section_3} images={attributes.images} />
					<InnerworksDark data={attributes.content.section_4} images={attributes.images} />
					<InnerworksOptimization data={attributes.content.section_5} images={attributes.images} />
					<InnerworksAchievements data={attributes.content.section_6} images={attributes.images} />
					<InnerworksScreens data={attributes.content.section_7} images={attributes.images} />
					<InnerworksPlans data={attributes.content.section_8} images={attributes.images} />
					<InnerworksLast data={attributes.content.section_9} images={attributes.images} />
					{attributes?.sections?.data[0] && <ReviewSection data={attributes.sections.data[0]} />}
					<GetInTouch images={attributes.images} />
				</PopupProvider>
			)}
		</MainLayout>
	);
};
