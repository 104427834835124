import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';

export const InnerworksAbout = ({ data, images }) => {
	return (
		<Wrapper>
			<Number>
				<SkImage {...images.find((image) => image.name === data.number).image} />
			</Number>
			<Title>{data.title}</Title>
			<Content>
				<Image>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</Image>
				<TextWrapper>
					<Text>{data.text}</Text>
					<Items>
						{data.items.map((item) => (
							<Item key={item.title}>
								<IconWrapper>
									<RemixIcon className={item.icon} />
								</IconWrapper>
								<ItemContent>
									<ItemTitle>{item.title}</ItemTitle>
									<Description>{item.description}</Description>
								</ItemContent>
							</Item>
						))}
					</Items>
				</TextWrapper>
			</Content>
		</Wrapper>
	);
};

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	color: ${(p) => p.theme.colors.dark};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
        text-align: left;
        margin-left: 100px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.dark};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 24px;
	padding: 80px 16px 40px 16px;
	${media.md`
        padding: 60px 24px;
        gap: 40px;
    `}
	${media.xl`
        padding: 80px;
        gap: 60px;
    `}
    ${media.xxl`
        padding: 80px 0;
        margin: 0 auto;
        max-width: 1460px;
    `}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        gap: 40px;
    `}
	${media.xl`
        gap: 60px;
        flex-direction: row;
    `}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: inherit;
	z-index: 5;
	gap: 24px;
	${media.md`
        padding: 24px 0;
    `}
	${media.xl`
        gap: 40px;
        padding: 40px 0;
    `}
`;

const Number = styled.div`
	display: none;
	z-index: 1;
	${media.md`
        display: block;
        position: absolute;
        top: 87px;
        right: -32px;
        width: 240px;
        > img {
            width: 100%;
            height: auto;
        }
    `}
	${media.xl`
        top: 80px;
        right: 40px;
        width: 325px;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 8px;
`;

const Item = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	height: fit-content;
	width: fit-content;
	padding: 12px 20px;
	border-radius: 16px;
	background: ${(p) => p.theme.colors.white};
`;

const Description = styled.p`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.gray6};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
    `}
	color: ${(p) => p.theme.colors.dark};
`;

const IconWrapper = styled.div`
	color: ${(p) => p.theme.colors.red};
	display: flex;
	align-items: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
`;

const ItemContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const ItemTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
    `}
	color: ${(p) => p.theme.colors.gray6};
`;

const Image = styled.div`
	> img {
		width: 100%;
		height: auto;
	}
	width: 100%;
	${media.md`
        width: 260px;
        margin: 0 auto;
    `}
	${media.xl`
        min-width: 370px;
        margin: 0;
    `}
`;
